import { useAuth0 } from '@auth0/auth0-react'
import { Typography } from '@mui/material'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import queryString from 'query-string'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { URL_ARRAY_FORMAT } from '../../../../constants/comparison'
import { ICartTool } from '../../../../interfaces/cart-tool.interface'
import { IReduxState } from '../../../../state/interfaces/state.interface'

const navLinks = [
    {
        label: 'catalog',
        path: '/',
    },
    {
        label: 'cart',
        path: '/cart',
        onlyAuthenticated: false,
    },
    {
        label: 'comparison',
        path: '/comparison',
    },
]

const StyledContainer = styled.div`
    display: flex;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: column;
        align-items: flex-end;
    }
`

const StyledItem = styled.div`
    margin-right: 40px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-right: 32px;
    }
`

const menuOptionCss = css`
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin-bottom: 20px;
    }
`

const MenuOptionButton = styled.button`
    ${menuOptionCss}
    border: none;
    background: none;
    padding: 0;
`
interface IStyledActive {
    readonly $isActive?: boolean
}

const MenuOptionInnerLink = styled.div<IStyledActive>`
    ${menuOptionCss}
    position: relative;
    padding: 6px 6px;

    ${({ $isActive }) =>
        $isActive &&
        css`
            margin-top: 2px;
            border-bottom: 2px solid var(--text-primary-color);
        `}

    ${({ theme }) => theme.breakpoints.down('md')} {
        padding: 2px 6px;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        border-color: white;
    }
`

const StyledTypography = styled(Typography)<IStyledActive>`
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: var(--text-primary-color);

    &:hover {
        color: var(--text-secondary-color);
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            &:hover {
                color: var(--text-primary-color);
            }
        `}

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-weight: 300;
        color: white;

        &:hover {
            color: var(--grey-400-color);
        }

        ${({ $isActive }) =>
            $isActive &&
            css`
                &:hover {
                    color: white;
                }
            `}
    }
`

const StyledCountLabel = styled.div`
    position: absolute;
    top: 0;
    right: -24px;
    min-width: 28px;
    padding: 0 4px;
    box-sizing: border-box;
    text-align: center;
    background: var(--primary-main-color);
    border-radius: 8px;
    color: #fff;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        min-width: 20px;
        right: -19px;
    }
`

const StyledCountLabelTypography = styled(Typography)`
    font-size: 10px;
`

const HeaderNav: FC = () => {
    const { t } = useTranslation()
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
    const { pathname } = useLocation()
    const [toolIdsString, setToolIdsString] = useState<string | undefined>()
    const [comparisonToolIds, setComparisonToolIds] = useState<number[]>([])
    const [cartTools, setCartTools] = useState<ICartTool[]>([])
    const tools = useSelector((state: IReduxState) => state.tools)

    useEffect(() => {
        const ids = queryString.stringify(
            { ids: comparisonToolIds },
            {
                arrayFormat: URL_ARRAY_FORMAT,
            }
        )
        setToolIdsString(ids)
        setComparisonToolIds(tools.comparedToolIds)
        setCartTools(tools.cartTools)
    }, [tools.comparedToolIds.join(','), tools.cartTools.map(({ id }) => id).join(',')])

    const comparisonCount = `${comparisonToolIds.length}/6`
    const cartCount = cartTools.length

    const isLoginEnabled = process.env.GATSBY_IS_LOGIN_ENABLED === 'true'
    const pathnameWithTrimmedLastSlash =
        pathname.length > 1 ? pathname.replace(/\/$/, '') : pathname

    return (
        <StyledContainer>
            {navLinks.map(({ label, path, onlyAuthenticated }) => {
                if (onlyAuthenticated && !isAuthenticated) {
                    return null
                }

                const isComparison = label === 'Comparison'
                const isCart = label === 'Cart'

                const linkUrl = isComparison && toolIdsString ? `${path}?${toolIdsString}` : path
                const count = isComparison ? comparisonCount : isCart ? cartCount : null

                const showBadge = isComparison ? comparisonToolIds.length > 0 : cartTools.length > 0
                const isActive = pathnameWithTrimmedLastSlash === path

                return (
                    <StyledItem key={label}>
                        <Link to={linkUrl}>
                            <MenuOptionInnerLink $isActive={isActive}>
                                <StyledTypography $isActive={isActive}>
                                    {t(`header:${label}`)}
                                </StyledTypography>
                                {showBadge && (
                                    <StyledCountLabel>
                                        <StyledCountLabelTypography>
                                            {count}
                                        </StyledCountLabelTypography>
                                    </StyledCountLabel>
                                )}
                            </MenuOptionInnerLink>
                        </Link>
                    </StyledItem>
                )
            })}
            {isLoginEnabled && !isAuthenticated && (
                <StyledItem>
                    <MenuOptionButton onClick={() => loginWithRedirect()}>
                        <StyledTypography>{t('header:sign-in')}</StyledTypography>
                    </MenuOptionButton>
                </StyledItem>
            )}
            {isAuthenticated && (
                <StyledItem>
                    <Link to='/account'>
                        <MenuOptionInnerLink>
                            <StyledTypography>{t('header:account')}</StyledTypography>
                        </MenuOptionInnerLink>
                    </Link>
                </StyledItem>
            )}
            {isAuthenticated && (
                <StyledItem>
                    <MenuOptionButton
                        onClick={() => {
                            logout()
                        }}
                    >
                        <StyledTypography>{t('header:sign-out')}</StyledTypography>
                    </MenuOptionButton>
                </StyledItem>
            )}
        </StyledContainer>
    )
}

// Linttest with yarn fix

export default HeaderNav
